import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import Rocketbot from './Rocketbot';
import Newiebot from './NewieBot';
import GyaBot from './GyaBot';
import MacmaBot from './MacmaBot';
import InterBot from './InterBot';
import AsistenteBot from './AsistenteBot';
import Vision from './Vision';
import GetInfoPdf from './GetInfoPdf';
import AcccountStatements from './AcccountStatements';
import SwarmIA from './SwarmAI';
import ChatPage from './SwarmAI/pages/Chat';
import Mailroom from './mailroom';
import JiraBot from './jiraBot';
import Login from './jira_bot_login';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Rocketbot />} />
        <Route exact path="/newie" element={<Newiebot />} />
        <Route exact path="/gya" element={<GyaBot />} />
        <Route exact path="/asistente" element={<MacmaBot />} />
        <Route exact path="/inter" element={<InterBot />} />
        <Route exact path="/vision" element={<Vision />} />
        <Route exact path="/get-information-pdf" element={<GetInfoPdf />} />
        <Route exact path="/swarm-ia" element={<SwarmIA />} />
        <Route exact path="/swarm-ia/chat" element={<ChatPage />} />
        <Route exact path="/cotizador" element={<AsistenteBot />} />
        <Route exact path="/mailroom" element={<Mailroom />} />
        <Route exact path="/jiraBot" element={<JiraBot />} />
        <Route exact path="/loginJiraBot" element={<Login />} />
        <Route
          exact
          path="/account-statements"
          element={<AcccountStatements />}
        />
      </Routes>
    </Router>
  );
}

export default App;
