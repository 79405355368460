
// src/serviceWorkerRegistration.js

// Este archivo es una versión simplificada del archivo de registro del service worker proporcionado por create-react-app.

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] es la dirección IPv6 localhost.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 son considerados localhost para IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])){3}$/
    )
  );
  
  export function register(config) {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      // El constructor de URL está disponible en todos los navegadores que soportan SW.
      const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
      if (publicUrl.origin !== window.location.origin) {
        // Nuestro service worker no funcionará si PUBLIC_URL está en un origen diferente
        // del que nuestra página está sirviendo. Esto podría suceder si un CDN está siendo usado para servir activos.
        return;
      }
  
      window.addEventListener('load', () => {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
  
        if (isLocalhost) {
          // Esto se está ejecutando en localhost. Vamos a verificar si un service worker aún existe o no.
          checkValidServiceWorker(swUrl, config);
  
          // Agrega algunos registros adicionales a localhost, apuntando a los desarrolladores a la documentación del service worker/PWA.
          navigator.serviceWorker.ready.then(() => {
            console.log(
              'Esta aplicación web está siendo servida en caché por un service worker.'
            );
          });
        } else {
          // No es localhost. Solo registra el service worker.
          registerValidSW(swUrl, config);
        }
      });
    }
  }
  
  function registerValidSW(swUrl, config) {
    navigator.serviceWorker
      .register(swUrl)
      .then(registration => {
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (installingWorker == null) {
            return;
          }
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                // En este punto, el contenido precacheado ha sido actualizado,
                // pero el service worker anterior aún servirá el contenido antiguo hasta que todas las pestañas del cliente se cierren.
                console.log(
                  'Nuevo contenido está disponible y se servirá cuando todas las pestañas del cliente estén cerradas.'
                );
  
                // Ejecuta la callback
                if (config && config.onUpdate) {
                  config.onUpdate(registration);
                }
              } else {
                // En este punto, todo ha sido precacheado.
                // Es el momento perfecto para mostrar una "El contenido está en caché para su uso sin conexión".
                console.log('El contenido está en caché para su uso sin conexión.');
  
                // Ejecuta la callback
                if (config && config.onSuccess) {
                  config.onSuccess(registration);
                }
              }
            }
          };
        };
      })
      .catch(error => {
        console.error('Error durante el registro del service worker:', error);
      });
  }
  
  function checkValidServiceWorker(swUrl, config) {
    // Verifica si el service worker puede ser encontrado. Si no puede recargar la página.
    fetch(swUrl, {
      headers: { 'Service-Worker': 'script' },
    })
      .then(response => {
        // Asegúrate de que el service worker exista, y que realmente estemos obteniendo un archivo JavaScript.
        const contentType = response.headers.get('content-type');
        if (
          response.status === 404 ||
          (contentType != null && contentType.indexOf('javascript') === -1)
        ) {
          // No se encontró el service worker. Probablemente un nuevo despliegue.
          navigator.serviceWorker.ready.then(registration => {
            registration.unregister().then(() => {
              window.location.reload();
            });
          });
        } else {
          // Service worker encontrado. Procede como de costumbre.
          registerValidSW(swUrl, config);
        }
      })
      .catch(() => {
        console.log(
          'No se encontró conexión a Internet. La aplicación está funcionando en modo sin conexión.'
        );
      });
  }
  
  export function unregister() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then(registration => {
          registration.unregister();
        })
        .catch(error => {
          console.error(error.message);
        });
    }
  }