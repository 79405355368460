import React, { Component } from 'react';
import axios from 'axios';
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa'; // Importar íconos de micrófono
import { Navigate } from 'react-router-dom';

class JiraBot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      isLoading: false, // Estado para manejar la visibilidad del loader
      mediaRecorder: null,
      audioChunks: [],
      apiResponse: '' // Estado para almacenar la respuesta de la API
    };
  }

  componentDidMount() {
    const username = localStorage.getItem('username');
    if (!username) {
      this.setState({ redirectToLogin: true }); // Activa la redirección
    }
  }

  startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.ondataavailable = (event) => {
          this.setState(prevState => ({
            audioChunks: [...prevState.audioChunks, event.data]
          }));
        };
        mediaRecorder.start();
        this.setState({ mediaRecorder, isRecording: true, apiResponse: '' }); // Limpiar el mensaje de respuesta
      });
  }

  stopRecording = () => {
    const { mediaRecorder } = this.state;
    mediaRecorder.stop();
    mediaRecorder.onstop = this.handleStop;
    this.setState({ isRecording: false, isLoading: true }); // Mostrar el loader
  }

  handleStop = async () => {
    const { audioChunks } = this.state;
    const audioBlob = new Blob(audioChunks, { type: 'audio/mp3' });
    const base64Audio = await this.convertToBase64(audioBlob);
    if (base64Audio) {
      this.sendAudio(base64Audio);
    } else {
      console.error('Failed to convert audio to base64');
      this.setState({ isLoading: false }); // Ocultar el loader en caso de error
    }
    this.setState({ audioChunks: [] });
  }

  convertToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64Audio = reader.result.split(',')[1];
        resolve(base64Audio);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }

  sendAudio = (base64Audio) => {
    const username = localStorage.getItem('username'); // Retrieve username from localStorage
    const data = { base64Audio, solicitante: username };
    axios.post('https://apichat.therocketcode.mx/jiraBot', data)
      .then(response => {
        this.setState({ apiResponse: response.data.message.message, isLoading: false }); // Almacenar la propiedad message.message de la respuesta de la API en el estado y ocultar el loader
      })
      .catch(error => {
        console.error('There was an error!', error);
        this.setState({ apiResponse: 'There was an error!', isLoading: false }); // Almacenar el error en el estado y ocultar el loader
      });
  }

  render() {
    const { isRecording, isLoading, apiResponse } = this.state;
    if (this.state.redirectToLogin) {
      return <Navigate to="/loginJiraBot" />; // Redirección si no hay usuario
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', padding: '20px' }}>
        <header style={{ marginBottom: '50px' }}></header>
        <p style={{ marginBottom: '20px', fontSize: '16px', textAlign: 'center' }}>
          Presiona el botón y dictale a JiraAI qué tarea quieres crear, el proyecto donde quieres que se cree y la persona a la que se asignará la tarea.
        </p>
        <div 
          onClick={isRecording ? this.stopRecording : this.startRecording}
          style={{
            backgroundColor: isRecording ? '#f44336' : '#4CAF50', /* Red if recording, Green otherwise */
            border: 'none',
            color: 'white',
            padding: '15px',
            borderRadius: '50%',
            textAlign: 'center',
            textDecoration: 'none',
            display: 'inline-block',
            fontSize: '24px',
            cursor: 'pointer'
          }}
        >
          {isRecording ? <FaMicrophoneSlash /> : <FaMicrophone />}
        </div>
        {isLoading && (
          <div style={{ marginTop: '20px', fontSize: '16px', textAlign: 'center', color: 'white' }}>
            <p>Cargando...</p>
          </div>
        )}
        {apiResponse && !isLoading && (
          <div style={{ marginTop: '20px', fontSize: '16px', textAlign: 'center', color: 'white', width: '100%', maxWidth: '600px', wordWrap: 'break-word' }}>
            <p>Respuesta:</p>
            <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{apiResponse}</pre>
          </div>
        )}
      </div>
    );
  }
}

export default JiraBot;