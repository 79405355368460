import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './jira_bot_login.css';

const Login = () => {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      navigate('/jiraBot');
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://apichat.therocketcode.mx/jiraBotLogin', { user, password });
      if (response.data.statusCode === 200) {
        localStorage.setItem('user', JSON.stringify({ user, token: response.data.response.token }));
        localStorage.setItem('username', user); // Save username to localStorage
        navigate('/jiraBot');
      } else {
        setError(response.data.error);
      }
    } catch (err) {
      setError('Error en la autenticación. Por favor, inténtalo de nuevo.');
    }
  };

  return (
    <div className="container">
      <form className="form" onSubmit={handleSubmit}>
        <h2 style={{ color: '#fff', fontSize: '1.5rem' }}>Iniciar Sesión</h2>
        <input
          className="input"
          type="text"
          placeholder="Usuario"
          value={user}
          onChange={(e) => setUser(e.target.value)}
          required
        />
        <input
          className="input"
          type="password"
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button className="button" type="submit">Iniciar Sesión</button>
        {error && <div className="error-message">{error}</div>}
      </form>
    </div>
  );
};

export default Login;